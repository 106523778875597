import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      store.state.auth.status.loggedIn
        ? import('../views/Timeline.vue')
        : import('../views/Landing.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/login/oauth/google',
    name: 'login_google',
    component: () => import('../views/LoginGoogle.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/login/oauth/facebook',
    name: 'login_facebook',
    component: () => import('../views/LoginFacebook.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/login/oauth/youtube',
    name: 'login_youtube',
    component: () => import('../views/LoginYoutube.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/login/oauth/twitch',
    name: 'login_twitch',
    component: () => import('../views/LoginTwitch.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/login/oauth/twitter',
    name: 'login_twitter',
    component: () => import('../views/LoginTwitter.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/register/check-email',
    name: 'register_check_email',
    component: () => import('../views/RegisterCheckEmail.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/register/confirm/:token',
    name: 'register_confirm',
    component: () => import('../views/RegisterConfirmEmail.vue'),
    props: true,
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/register/invitation/:affiliate_code',
    name: 'register_invitation',
    component: () => import('../views/RegisterInvite.vue'),
    props: true,
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/resetting/request',
    name: 'resetting_request',
    component: () => import('../views/ResetRequest.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/resetting/check-email',
    name: 'resetting_check_email',
    component: () => import('../views/ResetCheckEmail.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/resetting/reset/:token',
    name: 'resetting_reset',
    component: () => import('../views/Reset.vue'),
    props: true,
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/after-regisration',
    name: 'after_registration',
    component: () => import('../views/AfterRegistration.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/profil',
    name: 'profil',
    component: () => import('../views/Profile.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/profil/post/:id',
    name: 'profil_post',
    component: () => import('../views/ProfilePost.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/about-profile',
    name: 'about_profil',
    component: () => import('../views/AboutProfile.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/friend',
    name: 'friend',
    component: () => import('../views/Friend.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/friend/find',
    name: 'friend_find',
    component: () => import('../views/FriendFind.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/profile-video',
    name: 'profile_video',
    component: () => import('../views/Video.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/badges',
    redirect: '/:username/vitrine',
    name: 'badges'
  },
  {
    path: '/:username/vitrine',
    name: 'vitrine',
    component: () => import('../views/Vitrine.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/quests',
    name: 'quests',
    component: () => import('../views/Quests.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/streams',
    name: 'streams',
    component: () => import('../views/StreamNew.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/streams_new',
    name: 'streams_new',
    component: () => import('../views/StreamNew.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/streams',
    name: 'streams_show',
    component: () => import('../views/StreamShow.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/lobby',
    name: 'lobby',
    component: () => import('../views/Lobby.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/lobby/:id/show',
    name: 'lobby_show',
    component: () => import('../views/LobbyShow.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/classement',
    name: 'classement',
    component: () => import('../views/Classement.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/Blog.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/blog/detail/:id-:slug',
    name: 'blog_detail',
    component: () => import('../views/BlogShow.vue'),
    props: true,
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/favoris',
    name: 'favoris',
    component: () => import('../views/Favoris.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('../views/Messages.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/messages/:id/detail',
    name: 'messages_details',
    component: () => import('../views/MessagesDetails.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import('../views/Timeline.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/hub-profile-notifications',
    name: 'hub_profile_notifications',
    component: () => import('../views/Notifications.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/hub-profile-requests',
    name: 'hub_profile_requests',
    component: () => import('../views/FriendRequest.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('../views/Community.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group',
    name: 'group',
    component: () => import('../views/Group.vue'),
    meta: {
      requiresLogin: true
    },
    redirect: {
      name: 'community'
    }
  },
  {
    path: '/group/:slug/show',
    name: 'group_show',
    component: () => import('../views/GroupShow.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/show/post/:id',
    name: 'group_show_post',
    component: () => import('../views/GroupShowPost.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/lobby',
    name: 'group_lobby',
    component: () => import('../views/GroupLobby.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/lobby/:id/show',
    name: 'group_lobby_show',
    component: () => import('../views/GroupLobbyShow.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/info',
    name: 'group_info',
    component: () => import('../views/GroupInfo.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/members',
    name: 'group_members',
    component: () => import('../views/GroupMembers.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/signalements',
    name: 'group_signalements',
    component: () => import('../views/GroupSignalements.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/new',
    name: 'group_new',
    component: () => import('../views/GroupNew.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/:slug/edit',
    name: 'group_edit',
    component: () => import('../views/GroupEdit.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/manage',
    name: 'group_manage',
    component: () => import('../views/GroupManage.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/group/invitations',
    name: 'group_invitations',
    component: () => import('../views/GroupInvitation.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('../views/Page.vue'),
    meta: {
      requiresLogin: true
    },
    redirect: {
      name: 'community'
    }
  },
  {
    path: '/page/:slug/show',
    name: 'page_show',
    component: () => import('../views/PageShow.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/:slug/show/post/:id',
    name: 'page_show_post',
    component: () => import('../views/PageShowPost.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  // {
  //   path: '/page/:slug/lobby',
  //   name: 'page_lobby',
  //   component: PageLobby,
  //   props: true,
  //   meta: {
  //     requiresLogin: true
  //   }
  // },
  // {
  //   path: '/page/:slug/lobby/:id/show',
  //   name: 'page_lobby_show',
  //   component: PageLobbyShow,
  //   props: true,
  //   meta: {
  //     requiresLogin: true
  //   }
  // },
  {
    path: '/page/:slug/info',
    name: 'page_info',
    component: () => import('../views/PageInfo.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/:slug/members',
    name: 'page_members',
    component: () => import('../views/PageMembers.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/:slug/signalements',
    name: 'page_signalements',
    component: () => import('../views/PageSignalements.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/new',
    name: 'page_new',
    component: () => import('../views/PageNew.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/:slug/edit',
    name: 'page_edit',
    component: () => import('../views/PageEdit.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/manage',
    name: 'page_manage',
    component: () => import('../views/PageManage.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/page/invitations',
    name: 'page_invitations',
    component: () => import('../views/PageInvitation.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/conditions-d-utilisation',
    name: 'conditions_dutilisation',
    component: () => import('../views/Conditions.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/deletion',
    name: 'deletion',
    component: () => import('../views/Deletion.vue'),
    meta: {
      requiresLogin: false
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/account/password',
    name: 'account_password',
    component: () => import('../views/AccountPassword.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/account/social',
    name: 'account_social',
    component: () => import('../views/AccountSocial.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/Shop.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/shop/:id-:slug/detail',
    name: 'shop_detail',
    component: () => import('../views/ShopForfait.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/events',
    name: 'events',
    component: () => import('../views/Events.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/events/weekly',
    name: 'events_weekly',
    component: () => import('../views/EventsWeekly.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/:username/events/daily',
    name: 'events_daily',
    component: () => import('../views/EventsDaily.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import('../views/Marketplace.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/marketplace/:id-:slug/detail',
    name: 'marketplace_detail',
    component: () => import('../views/MarketplaceProduct.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/marketplace/cart',
    name: 'marketplace_cart',
    component: () => import('../views/MarketplaceCart.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/marketplace/checkout',
    name: 'marketplace_checkout',
    component: () => import('../views/MarketplaceCheckout.vue'),
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/marketplace/order/:uuid',
    name: 'marketplace_order',
    component: () => import('../views/MarketplaceOrder.vue'),
    props: true,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/Error404.vue')
  },
  {
    path: '/403',
    name: 'error_403',
    component: () => import('../views/Error403.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/Error404.vue')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    } else {
      return {
        top: 0
      }
    }
  }
})

export default router
